import React from "react";
import { graphql } from "gatsby";
import { Link, Typography } from "@mui/material";
import kebabCase from "lodash/kebabCase";
import Layout from "../components/layout";

const TagsPage = ({ data }) => {
  const allTags = data.allMessagesJson.group;
  return (
    <Layout title={`Tags`} url={`/tags/`}>
      <div>
      <Typography component="h1" variant="h3">Tags</Typography>
        <div className="tag-list">
          <ul>
            {allTags.map((tag) => (
              <li key={tag.fieldValue}>
                <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                  {tag.fieldValue.toLowerCase()} ({tag.totalCount})
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default TagsPage;

export const pageQuery = graphql`
query {
    allMessagesJson(sort: {fields: tags}) {
      group(field: tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
